<template>
  <Transition name="fade">
    <div v-if="value" class="access-info-modal" @click.self="handleClose">
      <div class="access-info-modal__dialog">
        <div class="access-info-modal__actions">
          <button class="access-info-modal-close-btn" @click="handleClose">
            <TheCrossIcon />
          </button>
        </div>
        <div class="access-info-modal__content">
          <ul class="access-info-items">
            <li v-for="(item, index) in items" :key="index" class="access-info-item">
              <div
                v-if="item.media && item.media.url"
                class="access-info-item__image"
                :style="`background-image:url(${item.media.url})`"
              />
              <div class="access-info-item__text access-info-item-text">
                <div v-if="item.title" class="access-info-item-text__title">{{ item.title }}</div>
                <div v-if="item.details" class="access-info-item-text__description">{{ item.details }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'AccessInfoModal',
  components: { TheCrossIcon },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
$width-sm: 541px;

.access-info-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.5);

  &__dialog {
    position: relative;
    max-width: 850px;
    max-height: 90vh;
    width: calc(100% - 30px);
    padding: 20px;
    background-color: #fff;
    overflow: hidden;

    @media (min-width: $width-sm) {
      flex-direction: row;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &-close-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    height: calc(100% - 15px);
    margin-top: 15px;
    padding-bottom: 20px;
    overflow-y: auto;
  }
}

.access-info {
  &-items {
    margin: 0;
    padding: 0;
  }

  &-item {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    @media (min-width: $width-sm) {
      flex-direction: row;
    }

    &__image {
      max-width: 300px;
      height: 24vh;
      margin-bottom: 15px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media (min-width: $width-sm) {
        flex-direction: row;
        flex: 1;
      }

      @media (min-width: $width-sm) and (max-width: 849px) {
        height: 22vw;
        margin-right: 20px;
      }

      @media (min-width: 850px) {
        min-width: 350px;
        max-width: 350px;
        height: 240px;
        margin-right: 25px;
      }
    }

    &__text {
      padding-right: 5px;

      @media (min-width: $width-sm) {
        flex: 1.5;
      }
    }

    &-text {
      &__title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #363636;
        text-transform: uppercase;

        @media (min-width: $width-sm) {
          margin-bottom: 15px;
        }

        @media (min-width: 768px) {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 5.5px;
        }
      }

      &__description {
        font-size: 14px;
        font-weight: 300;
        color: #afafaf;

        @media (min-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
